import React from "react";
import Jerry from "../assets/jerry.jpg";

const Hero = () => {
  return (
    <div
      id="about"
      className="flex flex-col justify-center py-10 bg-light-shade px-5  tablet:flex-row tablet:h-[vh]"
    >
      <div className="flex flex-col justify-center tablet:items-center bg-light-accent tablet:drop-shadow-lg rounded-xl tablet:p-16  tablet:flex-row tablet:h-[vh]">
        <div className="">
          <img
            className=" rounded-t-lg tablet:rounded-t-none tablet:w-[600px] tablet:h-[1.6 * w] tablet:pr-5 "
            src={Jerry}
            alt="Jerry"
          />
        </div>
        <div className="flex flex-col items-center  px-5 pb-5 text-center align-center bg-main-brand-col rounded-b-lg tablet:rounded-lg tablet:w-[500px] tablet:h-[1.6 * w]  ">
          <h1 className="text-4xl pb-4 font-serif text-white pt-3  tablet:pt-5">
            About Me
          </h1>
          <p className=" text-white pb-5 tablet:pb-5">
            Let me introduce myself, my name is Gerald Walsh and I have been a
            social worker for almost 22 years. As an individual, couples or
            family therapist, I bring an open attitude to the table and a desire
            to develop healthy, open and honest relationships. The counselling
            environment needs to be a safe and non-judgmental, space in which to
            examine hurts and disappointment. Some clients need validation, some
            skills and some a new perspective to consider. The literature speaks
            to the value of the counselling relationship as the main component
            for therapeutic success. For this reason I seek to hear a client
            before suggesting a treatment approach and to understand the immense
            emotions that could be playing a role. While there are many
            wonderful counsellors with an array of skills that may meet your
            needs, I look forward to discussing how I might be of service to
            you, your family or those important to you. Yours truly, Gerald
            Walsh MSW/RSW
          </p>
          <button className="bg-transparent py-2 px-4 border border-dark-shade rounded ease-in-out duration-200 hover:bg-dark-accent text-white font-semibold hover:text-white ">
            {" "}
            <a
              href="https://www.thestar.com/news/canada/through-the-eyes-of-a-cas-front-line-worker/article_31955a3c-8ed7-5bc8-b25f-1eda8b91b4bc.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              Toronto Star Article
            </a>{" "}
          </button>
          {/* <button className="bg-transparent py-2 px-4 border border-dark-shade rounded ease-in-out duration-200 hover:bg-dark-accent text-white font-semibold hover:text-white "> <a href={clientContract} download={clientContract} placeholder="client_contract" target="_blank" rel="noreferrer noopener">Consulting Contract</a> </button> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
