import React from "react";
import Jerry from "../assets/counsel.jpg";
import clientContract from "../assets/client_contract.pdf";

const Counselling = () => {
  return (
    <div
      id="counsel"
      className="flex flex-col justify-center py-10 bg-light-shade px-5  tablet:flex-row tablet:h-[vh]"
    >
      <div className="flex flex-col justify-center tablet:items-center bg-light-accent tablet:drop-shadow-lg rounded-xl tablet:p-16  tablet:flex-row tablet:h-[vh]">
        <div className="">
          <img
            className=" rounded-t-lg tablet:rounded-t-none tablet:w-[600px] tablet:h-[1.6 * w] tablet:pr-5 "
            src={Jerry}
            alt="Jerry"
          />
        </div>
        <div className="flex flex-col items-center  px-5 pb-5 text-center align-center bg-main-brand-col rounded-b-lg tablet:rounded-lg tablet:w-[500px] tablet:h-[1.6 * w]  ">
          <h1 className="text-4xl pb-4 font-serif text-white pt-3  tablet:pt-5">
            Counselling
          </h1>
          <p className=" text-white pb-5 tablet:pb-5">
            There are many wonderful counsellors that can provide clinical
            services covering a broad range of issues. I offer specific
            counselling centered on issues pertinent to the family unit. These
            include relationship dynamics such as domestic conflict, domestic
            violence, divorce and separation and child wellbeing issues. I have
            several years’ experience co-facilitating domestic violence groups
            and parenting programs for men and over two decades of investigative
            experience in child welfare to offer specific insights into how
            family issues can negatively impact both parents and children. My
            insights and work allow me to engage in conversations and problem
            solving on issues related to parenting, step parent boundaries,
            discipline, the role and responsibility of men with their children,
            concepts of masculinity, and family dynamic issues related to
            communication and trust, intimacy and safety.
          </p>
          <button className="bg-transparent py-2 px-4 border border-dark-shade rounded ease-in-out duration-200 hover:bg-dark-accent text-white font-semibold hover:text-white ">
            {" "}
            <a
              href={clientContract}
              download={clientContract}
              placeholder="client_contract"
              target="_blank"
              rel="noreferrer noopener"
            >
              Counselling Contract
            </a>{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Counselling;
