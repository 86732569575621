import React from "react";
import Jerry from "../assets/consult.jpg";
import clientContract from "../assets/client_contract.pdf";

const Consulting = () => {
  return (
    <div
      id="consult"
      className="flex flex-col justify-center py-10 bg-light-shade px-5  tablet:flex-row tablet:h-[vh]"
    >
      <div className="flex flex-col-reverse justify-center tablet:items-center bg-light-accent tablet:drop-shadow-lg rounded-xl tablet:p-16  tablet:flex-row tablet:h-[vh]">
        <div className="flex flex-col items-center  px-5 pb-5 text-center align-center bg-main-brand-col rounded-b-lg tablet:rounded-lg tablet:w-[500px] tablet:h-[1.6 * w]  ">
          <h1 className="text-4xl pb-4 font-serif text-white pt-3  tablet:pt-5">
            Consultation
          </h1>
          <p className="text-white pb-5 tablet:pb-5">
            There are cases in which an outside opinion is required. These can
            include family and or legal issues regarding child welfare or
            custody and access disputes. If you are looking for an outside
            opinion related to issues of parental alienation, allegations of
            neglect or abuse including sexual abuse, I may be of some
            assistance. I have over two decades of child welfare investigative
            experience. My areas of interest are domestic conflict and violence,
            child abuse and neglect and issues such as caregiver fabrication,
            child sexual abuse, parental alienation, and denied access. My
            opinion has been sought out in both custody and access cases and
            civil litigation. Consultation services are predominantly sought out
            by the legal community. Should you be interested in this type of
            service, your legal counsel should contact me to set up a time to
            discuss the matter and what type of service is most appropriate.{" "}
          </p>
          <button className="bg-transparent py-2 px-4 border border-dark-shade rounded ease-in-out duration-200 hover:bg-dark-accent text-white font-semibold hover:text-white ">
            {" "}
            <a
              href={clientContract}
              download={clientContract}
              placeholder="client_contract"
              target="_blank"
              rel="noreferrer noopener"
            >
              Consulting Contract
            </a>{" "}
          </button>
        </div>
        <div className="">
          <img
            className=" rounded-t-lg tablet:rounded-t-none tablet:w-[600px] tablet:h-[1.6 * w] tablet:pl-5 "
            src={Jerry}
            alt="Jerry"
          />
        </div>
      </div>
    </div>
  );
};

export default Consulting;
